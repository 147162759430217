<script lang="ts" setup>
const emit = defineEmits<{
  'update:modelValue': [value: ZipCodeRange[]],
}>()

const props = withDefaults(defineProps<{
  allowAdd?: boolean,
  modelValue?: ZipCodeRange[]
}>(), {
  allowAdd: true
})

defineOptions({
  inheritAttrs: false
})

const inputValue = computed<ZipCodeRange[]>({
  get() {
    return !props.modelValue ? [] : props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const rowAdd = () => {
  const newValue = [...inputValue.value]
  newValue.push('')
  inputValue.value = newValue
}

const rowRemove = (index: number) => {
  const newValue = [...inputValue.value]
  newValue.splice(index, 1)
  inputValue.value = newValue
}

const rowUpdate = (index: number, key: string, value: number) => {
  const newValue = [...inputValue.value]
  newValue[index] = { ...newValue[index], [key]: value }
  inputValue.value = newValue
}
</script>


<template>
  <div class="space-y-2">
    <div v-for="(row, index) in inputValue" :key="index">
      <div class="flex gap-2">
        <div class="flex-1">
          <InputNumber
            :modelValue="row.start"
            :useGrouping="false"
            class="w-full"
            placeholder="Start"
            @update:modelValue="value => rowUpdate(index, 'start', value)"
          />
        </div>
        <div class="flex-1">
          <InputNumber
            :modelValue="row.end"
            :useGrouping="false"
            class="w-full"
            placeholder="End"
            @update:modelValue="value => rowUpdate(index, 'end', value)"
          />
        </div>
        <div class="flex-none">
          <Button
            icon="pi pi-trash"
            outlined
            severity="danger"
            @click="rowRemove(index)"
          />
        </div>
      </div>
    </div>

    <div v-if="allowAdd" class="flex justify-end">
      <Button
        icon="pi pi-plus"
        label="Add Another"
        size="small"
        @click="rowAdd"
      />
    </div>
  </div>
</template>
